import {
  Auth0Provider as LibAuth0Provider,
  CacheLocation,
} from "@auth0/auth0-react";
import { useRouter } from "next/router";
import React, { ReactNode } from "react";

const LOGIN_REDIRECT_URI = process.env.NEXT_PUBLIC_LOGIN_REDIRECT_URI ?? "";
const LOGIN_DOMAIN = process.env.NEXT_PUBLIC_LOGIN_DOMAIN ?? "";
const LOGIN_CLIENT_ID = process.env.NEXT_PUBLIC_LOGIN_CLIENT_ID ?? "";
const API_AUDIENCE = process.env.NEXT_PUBLIC_API_AUDIENCE ?? "";
const CACHE_LOCATION = (process.env.NEXT_PUBLIC_CACHE_LOCATION ??
  "memory") as CacheLocation;

interface Auth0ProviderProps {
  children: ReactNode;
}

export const Auth0Provider = ({ children }: Auth0ProviderProps) => {
  const router = useRouter();

  // upon successful login, redirect to the page they were trying to access
  const onRedirectCallback = (appState) => {
    // ensure we have this value and it is not empty
    if (appState?.returnTo && appState?.returnTo.length > 0) {
      router.push(decodeURIComponent(appState?.returnTo));
    }
  };

  return (
    <LibAuth0Provider
      domain={LOGIN_DOMAIN}
      clientId={LOGIN_CLIENT_ID}
      cacheLocation={CACHE_LOCATION}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: LOGIN_REDIRECT_URI,
        audience: API_AUDIENCE,
      }}
    >
      {children}
    </LibAuth0Provider>
  );
};
export default Auth0Provider;
