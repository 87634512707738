import { useRouter } from "next/router";
import { useEffect } from "react";
import ReactGA from "react-ga4";

export interface GoogleAnalyticsProps {
  trackingId: string;
}

export default function GoogleAnalytics({ trackingId }: GoogleAnalyticsProps) {
  const router = useRouter();

  const pageView = () => {
    const page = window?.location?.pathname;
    ReactGA.send({ hitType: "pageview", page });
  };

  useEffect(() => {
    ReactGA.initialize(trackingId);
    pageView();
  }, []);

  useEffect(() => {
    // the below will only fire on route changes (not initial load - that is handled in the script below)
    router.events.on("routeChangeComplete", pageView);
    return () => {
      router.events.off("routeChangeComplete", pageView);
    };
  }, [router.events]);

  return null;
}

export type EventOptions = {
  category: string;
  action: string;
  label?: string;
  value?: number;
};
export function trackEvent({ category, action, label, value }: EventOptions) {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
}
