import React from "react";

import ScreenCover from "../ScreenCover/ScreenCover";
import Spinner from "../Spinner/Spinner";

export default function FullScreenSpinner() {
  return (
    <ScreenCover>
      <Spinner />
    </ScreenCover>
  );
}
