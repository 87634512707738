import axios, { AxiosRequestHeaders, Method } from "axios";

import {
  GetChildrenPhoneNumbersResponseV1,
  GetIMessageBetaEnrollmentStatusResponseV1,
  GetMemoriesResponseV1,
  GetMemoryResponseV1,
  PostIMessageBetaEnrollmentResponseV1,
  PostSummarizeResponseV1,
} from "../../../../libs/ts/models/types";
import DeleteAccountInvitationResponse from "./Responses/DeleteAccountInvitationResponse";
import GetMeResponse from "./Responses/GetMeResponse";
import GetNotificationSettingsResponse from "./Responses/GetNotificationSettingsResponse";
import GetPendingAccountInvitationsResponse from "./Responses/GetPendingAccountInvitationsResponse";
import GetRemainingAccountInvitationsResponse from "./Responses/GetRemainingAccountInvitationsResponse";
import GetTimezonesResponse from "./Responses/GetTimezonesResponse";
import SubscriptionOptionsResponse from "./Responses/SubscriptionOptionsResponse";

const API_URL = process.env.NEXT_PUBLIC_API_URL;
const DEFAULT_RESP_TYPE = "json";

async function apiEndpointForPath(path = "") {
  return `${API_URL}${path}`;
}

interface DefaultHeaderValues {
  accessToken?: string;
}

interface DefaultHeaders extends AxiosRequestHeaders {
  Authorization?: string;
}

async function getDefaultHeaders(
  values?: DefaultHeaderValues
): Promise<DefaultHeaders> {
  const headers = {} as DefaultHeaders;
  if (!values) {
    return headers;
  }

  if (values.accessToken) {
    headers.Authorization = `Bearer ${values.accessToken}`;
  }
  return headers;
}

function formatPhoneNumberForWire(phoneNumber) {
  return phoneNumber.replace(/[^0-9]/g, "");
}

export async function getMe({ accessToken }): Promise<GetMeResponse> {
  const path = "/v1/me";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  const { data } = await axios.get(fullUrl, {
    headers,
    responseType: DEFAULT_RESP_TYPE,
  });
  return data;
}

export async function getOnboarding({ accessToken }) {
  const path = "/v1/onboarding";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.get(fullUrl, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function updateProfile({ accessToken, userProfile }) {
  const path = "/v1/me";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.post(fullUrl, userProfile, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function getAccount({ accessToken }) {
  const path = "/v1/account";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  const { data } = await axios.get(fullUrl, {
    headers,
    responseType: DEFAULT_RESP_TYPE,
  });
  return data;
}

export async function createAccount({ accessToken }) {
  const path = "/v1/account";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.post(
      fullUrl,
      {},
      {
        headers,
        responseType: DEFAULT_RESP_TYPE,
      }
    );
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function createChildren({ accessToken, children }) {
  const path = `/v1/children/multiple`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  const { data } = await axios.post(
    fullUrl,
    {
      children,
    },
    {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    }
  );
  return data;
}

export async function getAllChildren({ accessToken }) {
  const path = `/v1/children`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.get(fullUrl, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function getChildrenForAccount({ accessToken, accountId }) {
  const path = `/v1/account/${accountId}/children`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.get(fullUrl, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function getChildNumbers({
  accessToken,
}): Promise<GetChildrenPhoneNumbersResponseV1> {
  const path = `/v1/children/phonenumbers`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.get(fullUrl, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function updateChild({ accessToken, child }) {
  const path = `/v1/children/${child.ID}`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  const { data } = await axios.post(
    fullUrl,
    { Child: child },
    {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    }
  );
  return data;
}

export async function sendVerificationCode({ accessToken, phoneNumber }) {
  const path = "/v1/phone/sendverification";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.post(
      fullUrl,
      {
        PhoneNumber: formatPhoneNumberForWire(phoneNumber),
      },
      {
        headers,
        responseType: DEFAULT_RESP_TYPE,
      }
    );
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log(ex);
    return ex.response.data;
  }
}

export async function verifyPhoneNumber({
  accessToken,
  phoneNumber,
  verificationCode,
}) {
  const path = "/v1/phone/verify";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.post(
      fullUrl,
      {
        PhoneNumber: formatPhoneNumberForWire(phoneNumber),
        VerificationCode: verificationCode,
      },
      {
        headers,
        responseType: DEFAULT_RESP_TYPE,
      }
    );
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex.response.data);
    return ex.response.data;
  }
}

export async function getSubscriptionOptions({
  promo = null,
}): Promise<SubscriptionOptionsResponse> {
  const path = "/v1/subscription/options";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders();
  try {
    const { data } = await axios.get(fullUrl, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
      params: {
        promo,
      },
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function newSubscriptionCustomer({ accessToken }) {
  const path = "/v1/subscription/newcustomer";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.post(
      fullUrl,
      {},
      {
        headers,
        responseType: DEFAULT_RESP_TYPE,
      }
    );
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function createSubscriptionCheckout({
  accessToken,
  priceId,
  promoCode = null,
}) {
  const path = "/v1/subscription/checkout";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.post(
      fullUrl,
      {
        PriceID: priceId,
        PromoCode: promoCode,
      },
      {
        headers,
        responseType: DEFAULT_RESP_TYPE,
      }
    );
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function checkoutSuccess({ accessToken, sessionId }) {
  const path = "/v1/subscription/success";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.post(
      fullUrl,
      {
        SessionID: sessionId,
      },
      {
        headers,
        responseType: DEFAULT_RESP_TYPE,
      }
    );
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function getSubscription({ accessToken }) {
  const path = "/v1/subscription";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.get(fullUrl, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function getMemories({
  accessToken,
  childIds,
  dateRange,
  page,
}): Promise<GetMemoriesResponseV1> {
  const path = `/v1/memories`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  const params = {
    childIds,
    page,
    from: undefined,
    to: undefined,
  };

  params.from = dateRange?.from;
  params.to = dateRange?.to;

  const { data } = await axios.get(fullUrl, {
    headers,
    responseType: DEFAULT_RESP_TYPE,
    params,
  });
  return data;
}

// TODO: date range and paging
export async function getMemoriesForChild({
  accessToken,
  childId,
  dateRange,
  page,
}) {
  const path = `/v1/children/${childId}/memories`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const params = {
      page,
      from: undefined,
      to: undefined,
    };

    params.from = dateRange?.from;
    params.to = dateRange?.to;

    const { data } = await axios.get(fullUrl, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
      params,
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

// TODO: date range and paging
export async function getRecentMemories({ accessToken }) {
  const path = `/v1/memories?limit=5&sort=u`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.get(fullUrl, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function getTodaysMemories({ accessToken }) {
  const path = `/v1/memories/today`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.get(fullUrl, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function createMemory({ accessToken, memory }) {
  const path = `/v1/memories`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.post(fullUrl, memory, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function updateMemory({ accessToken, memory }) {
  const path = `/v1/memories/${memory.ID}`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.post(fullUrl, memory, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function deleteMemory({ accessToken, memory }) {
  const path = `/v1/memories/${memory.ID}`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.delete(fullUrl, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function deleteMedia({ accessToken, media }) {
  const path = `/v1/memories/media/${media.ID}`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.delete(fullUrl, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function attachUpload({ accessToken, attachment }) {
  const path = `/v1/upload/attach`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.post(fullUrl, attachment, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function getMemory({
  accessToken,
  memoryId,
}): Promise<GetMemoryResponseV1> {
  const path = `/v1/memories/${memoryId}`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  const { data } = await axios.get(fullUrl, {
    headers,
    responseType: DEFAULT_RESP_TYPE,
  });
  return data;
}

export async function getSharedMemory({ memoryId, sc }) {
  const path = `/v1/shared/memories/${memoryId}`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({});
  try {
    const { data } = await axios.get(fullUrl, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
      params: {
        sc, // share code
      },
    });
    return data;
  } catch (ex) {
    console.log(ex);
    return ex.response.data;
  }
}

export async function createMemoryShareUrl({ accessToken, memoryId }) {
  const path = `/v1/memories/${memoryId}/share`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  const { data } = await axios.post(
    fullUrl,
    {},
    {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    }
  );
  return data;
}

export async function getMemoryShareUrl({ accessToken, memoryId }) {
  const path = `/v1/memories/${memoryId}/share`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.get(fullUrl, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    if (ex.response.status === 404) {
      // Expected. This memory has no share url
      return null;
    }
    console.log(ex);
    throw ex;
  }
}

export async function deleteMemoryShareUrl({ accessToken, memoryId }) {
  const path = `/v1/memories/${memoryId}/share`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.delete(fullUrl, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    console.log(ex);
    return ex.response.data;
  }
}

export async function getTimezones({
  accessToken,
}): Promise<GetTimezonesResponse> {
  const path = "/v1/timezones";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.get(fullUrl, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function getNotificationSettings({
  accessToken,
}): Promise<GetNotificationSettingsResponse> {
  const path = "/v1/notifications/settings";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  const { data } = await axios.get(fullUrl, {
    headers,
    responseType: DEFAULT_RESP_TYPE,
  });
  return data;
}

export async function updateNotificationSettings({
  accessToken,
  notificationSettings,
}) {
  const path = "/v1/notifications/settings";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  console.log({ notificationSettings });
  try {
    const { data } = await axios.post(
      fullUrl,
      { NotificationSettings: notificationSettings },
      {
        headers,
        responseType: DEFAULT_RESP_TYPE,
      }
    );
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function resetPassword({ email }) {
  console.log({ email });
  const options = {
    method: "POST" as Method,
    url: `https://${process.env.NEXT_PUBLIC_LOGIN_DOMAIN}/dbconnections/change_password`,
    headers: { "content-type": "application/json" },
    data: {
      client_id: process.env.NEXT_PUBLIC_LOGIN_CLIENT_ID,
      email,
      connection: "Username-Password-Authentication",
    },
  };

  const resp = await axios.request(options);
  console.log(resp);
}

export async function getAccountInvitations({ accessToken }) {
  const path = "/v1/account/invitations";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.get(fullUrl, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function getSentAccountInvitations({
  accessToken,
}): Promise<GetPendingAccountInvitationsResponse> {
  const path = "/v1/account/sentinvitations";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  const { data } = await axios.get(fullUrl, {
    headers,
    responseType: DEFAULT_RESP_TYPE,
  });
  return data;
}

export async function getRemainingAccountInvitations({
  accessToken,
}): Promise<GetRemainingAccountInvitationsResponse> {
  const path = "/v1/account/remaininginvitations";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  const { data } = await axios.get(fullUrl, {
    headers,
    responseType: DEFAULT_RESP_TYPE,
  });
  return data;
}

export async function getInvitationDetails({ accessToken, invitationId }) {
  const path = `/v1/account/invitations/${invitationId}`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.get(fullUrl, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function joinAccount({ accessToken, invitationId }) {
  const path = `/v1/account/invitations/${invitationId}/join`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.post(
      fullUrl,
      {},
      {
        headers,
        responseType: DEFAULT_RESP_TYPE,
      }
    );
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function sendAccountInvitation({ accessToken, phoneNumber }) {
  const path = `/v1/account/invitations`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.post(
      fullUrl,
      { PhoneNumber: formatPhoneNumberForWire(phoneNumber) },
      {
        headers,
        responseType: DEFAULT_RESP_TYPE,
      }
    );
    return data;
  } catch (ex) {
    console.log(ex);
    return ex.response.data;
  }
}

export async function deleteAccountInvitation({
  accessToken,
  invitationId,
}): Promise<DeleteAccountInvitationResponse> {
  const path = `/v1/account/invitations/${invitationId}`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  const { data } = await axios.delete(fullUrl, {
    headers,
    responseType: DEFAULT_RESP_TYPE,
  });
  return data;
}

export async function getAccountUsers({ accessToken }) {
  const path = "/v1/account/users";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });

  const { data } = await axios.get(fullUrl, {
    headers,
    responseType: DEFAULT_RESP_TYPE,
  });
  return data;
}

export async function removeAccountUser({ accessToken, userId }) {
  const path = `/v1/account/users/${userId}`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.delete(fullUrl, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function getUploadUrl({ accessToken, fileMeta }) {
  const path = "/v1/upload/url";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.post(fullUrl, fileMeta, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log("api error", ex);
  }
}

export async function getPreviewMedia({ memoryId, pt }) {
  const path = `/v1/preview/memories/${memoryId}`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders();
  try {
    const { data } = await axios.get(fullUrl, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
      params: {
        pt, //preview token
      },
    });
    return data;
  } catch (ex) {
    //window.location.href = `${WWW_HOST}${window.location.pathname}`;
    console.log(ex);
    return ex?.response?.data;
  }
}

export async function resendChildMonthEmail({
  accessToken,
  childId,
  targetDate,
}) {
  const path = `/v1/email/monthly/${childId}/resend`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });

  const { data } = await axios.post(
    fullUrl,
    {
      TargetDate: targetDate,
    },
    {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    }
  );
  return data;
}

export async function getIMmessageBetaEnrollStatus({
  accessToken,
  childId,
}): Promise<GetIMessageBetaEnrollmentStatusResponseV1> {
  const path = `/v1/beta/imessage?child=${childId}`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  const { data } = await axios.get(fullUrl, {
    headers,
    responseType: DEFAULT_RESP_TYPE,
  });
  return data;
}

export async function enrollInIMmessageBeta({
  accessToken,
  childId,
}): Promise<PostIMessageBetaEnrollmentResponseV1> {
  const path = "/v1/beta/imessage";
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  const { data } = await axios.post(
    fullUrl,
    {
      ChildID: childId,
    },
    {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    }
  );
  return data;
}

export async function search({ accessToken, childIds, query }) {
  const path = `/v1/search`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  const { data } = await axios.post(
    fullUrl,
    { ChildIDs: childIds, Query: query },
    {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    }
  );
  return data;
}

export async function summarize({
  accessToken,
  groups,
}): Promise<PostSummarizeResponseV1> {
  const path = `/v1/summarize`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  const { data } = await axios.post(
    fullUrl,
    { Groups: groups },
    {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    }
  );
  return data;
}

export async function getMyReferralCode({ accessToken }) {
  const path = `/v1/referrals/mycode`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  try {
    const { data } = await axios.get(fullUrl, {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    });
    return data;
  } catch (ex) {
    console.log(ex);
    return ex?.response?.data;
  }
}

export async function postEmoji({
  memoryId,
  accessToken,
  author,
  subject,
  message,
}) {
  const path = `/v1/emoji`;
  const fullUrl = await apiEndpointForPath(path);
  const headers = await getDefaultHeaders({ accessToken });
  const { data } = await axios.post(
    fullUrl,
    { Message: message, MemoryID: memoryId, Author: author, Subject: subject },
    {
      headers,
      responseType: DEFAULT_RESP_TYPE,
    }
  );
  return data;
}
