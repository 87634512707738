import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import React, { ReactNode } from "react";

import { stylesCache } from "../util/StylesCache";

export const AppColors: Record<string, [string]> = {
  disabled: ["#bdbdbd"],
  mmPurple: ["#ab47b0"],
  mmLightBlue: ["#29b6f6"],
};

export interface ThemeProviderProps {
  children: ReactNode;
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      emotionCache={stylesCache}
      theme={{
        colors: {
          ...AppColors,
        },
        fontSizes: {
          xs: "10",
          sm: "12",
          md: "14",
          lg: "16",
          xl: "20",
        },
        primaryColor: "grape",

        /**
         * The default shadow settings were too light for my taste
         */
        shadows: {
          xs: "1px 1px 1px 1px rgba(0, 0, 0, .25)",
          sm: "2px 2px 2px 2px rgba(0, 0, 0, .25)",
          md: "3px 3px 3px 3px rgba(0, 0, 0, .25)",
          lg: "4px 4px 4px 4px rgba(0, 0, 0, .25)",
          xl: "5px 5px 5px 5px rgba(0, 0, 0, .25)",
        },
      }}
    >
      <Notifications />
      {children}
    </MantineProvider>
  );
}
