import Script from "next/script";
import React, { useContext, useEffect } from "react";

import AuthContext from "../../contexts/Auth";

const REDDIT_PIXEL_ID = process.env.NEXT_PUBLIC_REDDIT_PIXEL_ID ?? "";

export function RedditPixelHead() {
  return (
    <Script>
      {`
        <!-- Reddit Pixel -->
        !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);
        <!-- End Reddit Pixel -->
      `}
    </Script>
  );
}

export function RedditPixelBody() {
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    (window as any)?.rdt?.("init", REDDIT_PIXEL_ID);
    trackEvent(EventType.PageVisit);
  }, []);

  // when the user is determined pass that to rdt for identification
  useEffect(() => {
    if (currentUser?.email) {
      (window as any)?.rdt?.("init", REDDIT_PIXEL_ID, {
        email: currentUser.email,
      });
    }
  }, [currentUser]);

  return null;
}

enum EventType {
  PageVisit = "PageVisit",
  ViewContent = "ViewContent",
  Search = "Search",
  AddToCart = "AddToCart",
  AddToWishlist = "AddToWishlist",
  Purchase = "Purchase",
  Lead = "Lead",
  SignUp = "SignUp",
}

export function trackEvent(eventType: EventType) {
  (window as any)?.rdt?.("track", eventType);
}

export function trackCustomEvent(eventName: string) {
  (window as any)?.rdt?.("track", "Custom", {
    customEventName: eventName,
  });
}

export default {
  RedditPixelHead,
  RedditPixelBody,
  EventType,
  trackEvent,
  trackCustomEvent,
};
