import type { BoxProps } from "@mantine/core";
import {
  Box,
  Grid,
  Loader,
  MantineSize,
  Text,
  useMantineTheme,
} from "@mantine/core";
import React from "react";

export interface SpinnerProps extends BoxProps {
  /**
   * Optional color override of the spinner
   */
  color?: string;
  /**
   * Size of the spinner
   */
  size?: MantineSize;
  /**
   * Optional message displayed under the spinner
   */
  message?: string;
}

export default function Spinner({
  color,
  size,
  message,
  sx,
  ...others
}: SpinnerProps) {
  const theme = useMantineTheme();

  const spinnerColor = color ?? theme.colors.grape[4];
  const spinnerSize = size ?? "md";

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "inherit",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
      className="spinner"
      {...others}
    >
      <Grid>
        <Grid.Col span={12} sx={{ textAlign: "center" }}>
          <Loader color={spinnerColor} size={spinnerSize} />
        </Grid.Col>
        <Grid.Col span={12}>
          {message && (
            <Text sx={{ textAlign: "center" }} color={theme.colors.gray[5]}>
              {message}
            </Text>
          )}
        </Grid.Col>
      </Grid>
    </Box>
  );
}
