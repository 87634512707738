import { useAuth0, User as Auth0User } from "@auth0/auth0-react";
import { useDevCycleClient } from "@devcycle/react-client-sdk";
import React, {
  createContext,
  Dispatch,
  ReactNode,
  useEffect,
  useState,
} from "react";

const API_AUDIENCE = process.env.NEXT_PUBLIC_API_AUDIENCE ?? "";

export interface AuthContextProps {
  currentUser?: Auth0User;
  accessToken?: string;
  setAccessToken?: Dispatch<any>;
  acquireAccessToken?: (
    allowUnauthenticated: boolean,
    showSignUp?: boolean
  ) => Promise<boolean>;
  isLoading?: boolean;
}

const AuthContext = createContext<AuthContextProps>({});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const dvcClient = useDevCycleClient();
  const [accessToken, setAccessToken] = useState<string>();
  const [currentUser, setCurrentUser] = useState<Auth0User>();

  // this is used to hold the access token until auth0 gives us the user information
  const [tempToken, setTempToken] = useState<string>();

  const { user, getAccessTokenSilently, loginWithRedirect, isLoading } =
    useAuth0();

  const holdForUser = async () => {
    if (!user || !tempToken) {
      return;
    }
    // Ensure that we identify the user before moving forward
    const feature_flag_user = {
      user_id: user.sub,
      email: user.email,
    };
    await dvcClient.identifyUser(feature_flag_user);
    setAccessToken(tempToken);
    setCurrentUser(user);
  };

  useEffect(() => {
    holdForUser();
  }, [tempToken, user]);

  const triggerLogin = (showSignUp: boolean) => {
    // casing is mixed per the documentation
    const options = {
      appState: {
        // path and query string
        returnTo: encodeURIComponent(
          window?.location?.pathname + window?.location?.search
        ),
      },
      screen_hint: showSignUp ? "signup" : undefined,
    };
    loginWithRedirect(options);
  };

  // This fn returns "true" when it was blocked from taking action either by an
  // existing token or if it is called multiple times in succession.
  let callingForToken = false;
  const acquireAccessToken = async (
    shouldLoginOnFailure: boolean,
    showSignUp?: boolean
  ): Promise<boolean> => {
    if (callingForToken || accessToken) {
      return true;
    }
    callingForToken = true;
    try {
      const tok = await getAccessTokenSilently({
        authorizationParams: {
          audience: API_AUDIENCE,
        },
      });
      setTempToken(tok);
    } catch (e) {
      // https://community.auth0.com/t/how-do-i-redirect-users-directly-to-the-hosted-signup-page/42520
      if (shouldLoginOnFailure) {
        triggerLogin(showSignUp);
      }
    } finally {
      callingForToken = false;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        accessToken,
        setAccessToken,
        acquireAccessToken,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
