import { Box } from "@mantine/core";
import React, { ReactNode } from "react";

interface ScreenCoverProps {
  children: ReactNode;
}
function ScreenCover({ children }: ScreenCoverProps) {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backdropFilter: "blur(5px)",
      }}
    >
      {children}
    </Box>
  );
}

export default ScreenCover;
