import Head from "next/head";
import Script from "next/script";
import React from "react";

export default function TrackDeskClickTracker() {
  return (
    <Head>
      <Script async src="//cdn.trackdesk.com/tracking.js" />

      <Script>
        {`
          (function(t,d,k){(t[k]=t[k]||[]).push(d);t[d]=t[d]||t[k].f||function(){(t[d].q=t[d].q||[]).push(arguments)}})(window,"trackdesk","TrackdeskObject"); 
          trackdesk('minime', 'click');
        `}
      </Script>
    </Head>
  );
}
