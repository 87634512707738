import React from "react";

// TODO: this should be refactored into a util folder

// First provider is outermost and last provider is innermost
export default function ProviderTree(providers) {
  const BuildProviderTree = (providers) => {
    if (providers.length === 1) {
      return providers[0];
    }
    const A = providers.shift();
    const B = providers.shift();
    return BuildProviderTree([
      ({ children }) => {
        return (
          <A>
            <B>{children}</B>
          </A>
        );
      },
      ...providers,
    ]);
  };

  return BuildProviderTree(providers);
}
