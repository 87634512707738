import React, {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useState,
} from "react";

import { GetTodaysMemoriesResponseV1 } from "../../../libs/ts/models/types";
import * as Api from "../components/ApiClient/Api";
import AuthContext from "./Auth";
import {
  MemoryCreatedSignal,
  MemoryDeletedSignal,
  MemoryUpdatedSignal,
} from "./Signals";

export interface TodaysMemoriesContextProps {
  memoriesData?: GetTodaysMemoriesResponseV1;
  setMemoriesData?: Dispatch<any>;
  loadMemoriesData?: () => void;
  summary?: string;
}

const TodaysMemoriesContext = createContext({
  memoriesData: undefined,
  setMemoriesData: undefined,
  loadMemoriesData: undefined,
  summary: undefined,
} as TodaysMemoriesContextProps);

interface TodaysMemoriesProviderProps {
  children: ReactNode;
}

export const TodaysMemoriesProvider = ({
  children,
}: TodaysMemoriesProviderProps) => {
  const { accessToken } = useContext(AuthContext);
  // const [summary, sumGroupUpdater] = useMemorySummary();
  // const recentMemoriesSummary = useFeatureFlag({
  //   flagName: "recent-memories-ai-summary",
  //   defaultValue: false,
  // });

  const [memoriesData, setMemoriesData] =
    useState<GetTodaysMemoriesResponseV1>();

  let isLoading = false;
  const loadMemoriesData = async (force?: boolean) => {
    if ((!accessToken || isLoading || memoriesData !== undefined) && !force) {
      return;
    }
    isLoading = true;
    const recentMemoriesResp = await Api.getTodaysMemories({ accessToken });
    const mediaLookup = {};
    recentMemoriesResp?.Media?.forEach((m) => {
      if (!mediaLookup[m.MemoryID]) {
        mediaLookup[m.MemoryID] = [];
      }
      mediaLookup[m.MemoryID].push(m);
    });

    const childLookup = {};
    recentMemoriesResp?.Children?.forEach((c) => {
      if (!childLookup[c.ID]) {
        childLookup[c.ID] = [];
      }
      childLookup[c.ID].push(c);
    });

    setMemoriesData({
      mediaLookup,
      childLookup,
      ...recentMemoriesResp,
    });

    // if (recentMemoriesSummary) {
    //   // now that we know the recent memories, render the summary
    //   sumGroupUpdater(
    //     summaryGroupsFromMemoryList(recentMemoriesResp?.Memories)
    //   );
    // }

    isLoading = false;
  };

  // subscribe to memory update events
  MemoryUpdatedSignal.useSubscription(() => loadMemoriesData(true));
  MemoryCreatedSignal.useSubscription(() => loadMemoriesData(true));
  MemoryDeletedSignal.useSubscription(() => loadMemoriesData(true));

  return (
    <TodaysMemoriesContext.Provider
      value={{
        memoriesData,
        setMemoriesData,
        loadMemoriesData,
        summary: undefined,
      }}
    >
      {children}
    </TodaysMemoriesContext.Provider>
  );
};
export default TodaysMemoriesContext;
