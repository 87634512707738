import { Context, useContext, useEffect, useState } from "react";

import { SummaryGroup } from "../../../../libs/ts/models/types";
import { Memory } from "../../../../libs/ts/models/types";
import AuthContext, { AuthContextProps } from "../../contexts/Auth";
import * as Api from "../ApiClient/Api";

export interface useMemorySummaryProps {
  context?: Context<AuthContextProps>;
  groups?: SummaryGroup[];
}

export default function useMemorySummary({
  context,
  groups,
}: useMemorySummaryProps = {}): [string, (groups: SummaryGroup[]) => void] {
  const { accessToken } = useContext(context ?? AuthContext);
  const [summary, setSummary] = useState<string>("");
  const [summaryGroups, setSummaryGroups] = useState<SummaryGroup[]>(groups);

  const load = async () => {
    if (!accessToken || (summaryGroups ?? []).length === 0) {
      return;
    }
    try {
      const { Summary: sum } = await Api.summarize({
        accessToken,
        groups: summaryGroups,
      });
      setSummary(sum ?? "");
    } catch (e) {
      setSummary("");
    }
  };

  useEffect(() => {
    load();
  }, [accessToken, summaryGroups]);

  const sumGroupUpdater = (groups: SummaryGroup[]) => {
    // ignore multi updates with the same groups
    if (JSON.stringify(groups) === JSON.stringify(summaryGroups)) {
      return;
    }
    setSummaryGroups(groups);
  };

  return [summary, sumGroupUpdater];
}

export function summaryGroupsFromMemoryList(
  memories?: Memory[]
): SummaryGroup[] {
  const memsPerChild = {};

  memories?.forEach((m) => {
    if (!memsPerChild[m.ChildID]) {
      memsPerChild[m.ChildID] = [];
    }
    memsPerChild[m.ChildID].push(m.ID);
  });

  return Object.keys(memsPerChild).map((k) => ({
    ChildID: k,
    MemoryIDs: memsPerChild[k],
  }));
}
