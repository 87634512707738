import "../styles/globals.css";

import {
  useIsDevCycleInitialized,
  withDevCycleProvider,
} from "@devcycle/react-client-sdk";
import Head from "next/head";
import React from "react";

import FacebookPixel from "../components/FacebookPixel/FacebookPixel";
import FullScreenSpinner from "../components/FullScreenSpinner/FullScreenSpinner";
import GoogleAnalytics from "../components/GoogleAnalytics/GoogleAnalytics";
import { RedditPixelHead } from "../components/RedditPixel/RedditPixel";
import TrackDeskClickTracker from "../components/TrackDesk/TrackDeskClickTracker";
import Viewport from "../components/Viewport/Viewport";
import { AuthProvider } from "../contexts/Auth";
import { Auth0Provider } from "../contexts/Auth0";
import { ChildListProvider } from "../contexts/ChildList";
import { RecentMemoriesProvider } from "../contexts/RecentMemories";
import { RemainingAccountInvitationsProvider } from "../contexts/RemainingAccountInvitations";
import { Rollbar } from "../contexts/Rollbar";
import { SignalProvider } from "../contexts/Signals";
import { ThemeProvider } from "../contexts/Theme";
import { TodaysMemoriesProvider } from "../contexts/TodaysMemories";
import ProviderTree from "../util/ProviderTree";

const SITE_TITLE = process.env.NEXT_PUBLIC_SITE_TITLE ?? "MiniMemories";
const GOOGLE_ANALYTICS_TRACKING_ID =
  process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID ?? "";
const FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID ?? "";
const DVC_CLIENT_SDK_KEY = process.env.NEXT_PUBLIC_DVC_CLIENT_SDK_KEY ?? "";

interface AppProps extends React.Component {
  Component: React.FunctionComponent;
  pageProps: any;
  children: React.ReactNode;
}

function App({ Component, pageProps, children }: AppProps) {
  const dvcReady = useIsDevCycleInitialized();

  // if getServerSideProps preloaded any open graph meta tags into the
  // page props inject them into the page head here. This ensures that
  // the values are read by the crawler even before the page body renders
  const openGraphMetaElements = Object.keys(pageProps?.openGraphMeta || {}).map(
    (propertyName) => {
      return (
        <meta
          key={propertyName}
          property={propertyName}
          content={pageProps?.openGraphMeta[propertyName]}
        />
      );
    }
  );

  const Providers = ProviderTree([
    ThemeProvider,
    Auth0Provider,
    AuthProvider,
    Rollbar,
    SignalProvider,
    RecentMemoriesProvider,
    TodaysMemoriesProvider,
    ChildListProvider,
    RemainingAccountInvitationsProvider,
  ]);

  return (
    <>
      <Head>
        {/* TODO: move to component */}
        <title>{SITE_TITLE}</title>
        {/* TODO: move to component */}
        {openGraphMetaElements}
      </Head>
      <Viewport />
      <TrackDeskClickTracker />
      <FacebookPixel pixelId={FACEBOOK_PIXEL_ID} />
      <GoogleAnalytics trackingId={GOOGLE_ANALYTICS_TRACKING_ID} />
      <RedditPixelHead />
      <Providers>
        {dvcReady ? (
          <Component {...pageProps}>{children}</Component>
        ) : (
          <FullScreenSpinner />
        )}
      </Providers>
    </>
  );
}

export default withDevCycleProvider({ sdkKey: DVC_CLIENT_SDK_KEY })(App);
